import type { BundleGeneratorMatchInfo, GeneratorBundle } from '../store';

interface BundlePrimaryAndMatch {
  primary: BundleGeneratorMatchInfo;
  match: BundleGeneratorMatchInfo;
}

export const determineBundlePrimaryAndMatch = (
  bundle: GeneratorBundle & { legs: BundleGeneratorMatchInfo[] }
): BundlePrimaryAndMatch => {
  switch (bundle.type) {
    case 'terminal-dual':
      return { primary: bundle.legs[bundle.legs.length - 1], match: bundle.legs[0] };
    default:
      return { primary: bundle.legs[0], match: bundle.legs[bundle.legs.length - 1] };
  }
};
