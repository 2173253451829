// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BundleRowItem {
  display: flex;
  flex-direction: column;
  width: 600px;
  border: 1px solid gray;
  border-radius: 5px;
}

  .BundleRowItem__bottom-section-container {
      display: flex;
      justify-content: center;
      height: 275px;
    }

  .BundleRowItem__stops-container {
    display: flex;
    padding: 5px;
  }

  .BundleRowItem__link-icon {
    background-color: #808080;
    color: #fff;
    height: 24px;
    width: 24px;
    margin: 5px;
    padding: 5px;
    border-radius: 2px;
  }
`, "",{"version":3,"sources":["webpack://./app/_blessed/components/features/Admin/features/BundleGenerator/BundleRow/components/BundleRowItem/style.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,YAAY;EACZ,sBAAsB;EACtB,kBAAkB;AAwBpB;;EArBI;MACE,aAAa;MACb,uBAAuB;MACvB,aAAa;IACf;;EAGF;IACE,aAAa;IACb,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,WAAW;IACX,YAAY;IACZ,WAAW;IACX,WAAW;IACX,YAAY;IACZ,kBAAkB;EACpB","sourcesContent":[".BundleRowItem {\n  display: flex;\n  flex-direction: column;\n  width: 600px;\n  border: 1px solid gray;\n  border-radius: 5px;\n\n  &__bottom-section {\n    &-container {\n      display: flex;\n      justify-content: center;\n      height: 275px;\n    }\n  }\n\n  &__stops-container {\n    display: flex;\n    padding: 5px;\n  }\n\n  &__link-icon {\n    background-color: #808080;\n    color: #fff;\n    height: 24px;\n    width: 24px;\n    margin: 5px;\n    padding: 5px;\n    border-radius: 2px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
