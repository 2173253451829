import { useSelector } from 'react-redux';
import { selectDepartmentUuidMap } from '@client/_blessed/store/entities';
import Stop from '@client/_blessed/store/entities/stops/model';
import DispatchPageOrderLegStop from '@client/pages/DispatchPage/components/DispatchPageOrderLegStop';
import Department from '@client/_blessed/store/entities/departments/model';
import { TerminalDualMetadata } from '../../../store';

import './style.css';

interface BundleRowStopProps {
  stop: Stop;
  stopIndex: number;
  isFirstOfLeg: boolean;
  isLastOfLeg: boolean;
  status: string | null;
  metadata?: TerminalDualMetadata;
  handleStopDepartmentChange: (newDepartmentUuid: string) => void;
}

function BundleRowStop({
  stop,
  stopIndex,
  isFirstOfLeg,
  isLastOfLeg,
  status,
  metadata,
  handleStopDepartmentChange
}: BundleRowStopProps) {
  const departments = useSelector(selectDepartmentUuidMap);

  const stopDepartments: Department[] = [];
  if (isLastOfLeg && metadata?.isTbdJoin && metadata?.possibleReturnTerminals?.length > 0) {
    // Push the TBD stop department
    stopDepartments.push(departments[stop.departmentUUID]);

    metadata.possibleReturnTerminals.forEach((terminalUuid) => {
      stopDepartments.push(departments[terminalUuid]);
    });
  }

  return (
    <div className="BundleRowStop">
      <DispatchPageOrderLegStop
        stop={stop}
        departmentsById={departments}
        containerNumber=""
        disableAdd
        hideGateTicketModal
        outgateIngateEditable={false}
        locked={stopDepartments.length === 0}
        progressNotifications={null}
        attachmentsById={{}}
        bundle={{}}
        status={status}
        isFirstStop={isFirstOfLeg}
        isFirstOfLeg={isFirstOfLeg}
        isLastStop={isLastOfLeg}
        isLastOfLeg={isLastOfLeg}
        orderId=""
        orderImportExport=""
        onDelete={() => {}}
        onFieldChange={(newDepartmentUuid: string) => {
          handleStopDepartmentChange(newDepartmentUuid);
        }}
        onOrderUpdated={() => {}}
        legId=""
        position={stopIndex}
        onExecuteAction={() => {}}
        toggleSyncDialog={() => {}}
        showSync={false}
        firstSyncEnabled={false}
        lastSyncEnabled={false}
        nextStop={null}
        isSystemGeneratedAppointment={false}
        onAddToEmailBatch={() => {}}
        nextLeg={null}
        prevLeg={null}
        leg={{}}
        fromBundleSidebar
        stopDepartments={stopDepartments}
        disableEditStopName={stopDepartments.length === 0}
        bundleLegRelation={{} as any}
      />
    </div>
  );
}

export default BundleRowStop;
