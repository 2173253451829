import { useAppDispatch } from '@client/_blessed/hooks/useAppDispatch';
import LinkIcon from '@components/deprecatedTookit/icons/fa/light/LinkIcon';
import { BundleGeneratorMatchInfo, GeneratorBundle, updateGeneratorBundleStopDepartment } from '../../../store';
import MatchRowStop from '../BundleRowStop';
import OrderInfo from './components/OrderInfo';

import './style.css';

interface BundleRowItemProps {
  generatorBundle: GeneratorBundle;
  isFirstLeg: boolean;
  isLastLeg: boolean;
  info: BundleGeneratorMatchInfo;
}

function BundleRowItem({ info, isFirstLeg, isLastLeg, generatorBundle }: BundleRowItemProps) {
  const baseClassName = 'BundleRowItem';

  const dispatch = useAppDispatch();

  return (
    <>
      <div className={baseClassName}>
        <OrderInfo matchInfo={info} />

        <div className={`${baseClassName}__bottom-section-container`}>
          <div>
            <h3>Leg: {info.legNumber}</h3>

            <div className={`${baseClassName}__stops-container`}>
              {info.stops.map((stop, index) => (
                <MatchRowStop
                  key={`${generatorBundle.bundleId}-${info.legNumber}-${stop.uuid} `}
                  stop={stop}
                  stopIndex={index}
                  isFirstOfLeg={index === 0}
                  isLastOfLeg={index === info.stops.length - 1}
                  status={index !== info.stops.length - 1 ? 'ready' : null}
                  metadata={isFirstLeg && generatorBundle.metadata}
                  handleStopDepartmentChange={(newDepartmentUuid: string) => {
                    dispatch(
                      updateGeneratorBundleStopDepartment({
                        bundleId: generatorBundle.bundleId,
                        legNumber: info.legNumber,
                        stopIndex: index,
                        departmentUuid: newDepartmentUuid
                      })
                    );
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      {!isLastLeg && <LinkIcon className={`${baseClassName}__link-icon`} />}
    </>
  );
}

export default BundleRowItem;
